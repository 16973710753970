<script>
import {email, required} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Swal from "sweetalert2";
import {useUserStore} from "@/store/user";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";
import {paginationHelper} from "@/helpers/pagination-helper";
import Multiselect from "vue-multiselect";
import CreateOrderModal from "@/components/order/create-order-modal.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {dateUtil} from "../../../helpers/date-util";
import {marketplaceOrderHelper} from "../../../helpers/marketplace-order-helper";

export default {
  computed: {
    marketplaceOrderHelper() {
      return marketplaceOrderHelper
    },
    dateUtil() {
      return dateUtil
    },
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    CustomCardSubtitle,
    Multiselect,
    Layout,
    PageHeader,
    CreateOrderModal
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
      },

      shops: null,
      shop: {},
      users: null,
      engines: null,
      markets: null,
      wordPressPlugins: new Map(),
      productLimits: new Map(),

      submitted: false,
      createOrEditShowModal: false,
      copyShopModal: false,

      changeShopUser: {
        shopId: "",
        visibleModal: false,

        users: null,
        usersIds: [],
        loading: false,


        selected: {
          id: '',
          user: {}
        }
      }
    };
  },

  validations() {
    const defaultFields = {
      name: {required},
      engineId: {required},
      marketId: {required}
    };

    if (this.getEngineName(this.shop.engineId) === 'engine.virtual') {
      return {
        shop: {
          ...defaultFields
        },

        changeShopUser: {
          selected: {
            id: {required}
          }
        }
      }
    }

    return {
      shop: {
        ...defaultFields,
        industryId: {required},
        domain: {
          required,
          domain: function (value) {
            return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value)
          }
        },
        emailAddress: {required, email}
      },

      changeShopUser: {
        selected: {
          id: {required}
        }
      }
    }
  },

  methods: {


    isWordPress(shop) {
      return this.$store.getters['engine/getEngineName'](shop.engineId) === 'engine.wordpress'
    },

    getWordPressPlugin(shop) {
      if (!this.wordPressPlugins || !shop.wordpressPluginMarketplaceOrderId) {
        return null
      }

      return this.wordPressPlugins.get(shop.id) || null;
    },

    getProductLimit(shop) {
      if (!this.productLimits || !shop.productLimitMarketplaceOrderId) {
        return null
      }

      return this.productLimits.get(shop.id) || null;
    },

    isWordPressPluginActive(shop) {
      const wordPressPlugin = this.getWordPressPlugin(shop)
      return this.isWordPress(shop) && wordPressPlugin && wordPressPlugin.internalStatus === "ACTIVE"
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    // eslint-disable-next-line no-unused-vars
    createOrEditShop() {
      // TODO: Resetuj produkty na sklepie, logotyp

      this.submitted = true;

      // stop here if form is invalid
      this.$v.shop.$touch();
      if (this.$v.shop.$invalid) {
        return;
      }

      const json = JSON.stringify(this.shop);

      axios.put(`/shop`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.shop.id ? "user-shops.edit-shop.success" : "user-shops.create-shop.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.shop.id) {
          for (const index in this.shops) {
            if (this.shops[index].id === this.shop.id) {
              this.shops[index] = this.shop;
              break;
            }
          }
        } else {
          let user = useUserStore().getUser;
          // setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
          this.shop.userId = user.id;
          this.shops.push(this.shop)

          if (this.users === null) {
            this.loadUsers()
          }
        }

        this.$refs.table.refresh()
        this.hideCreateOrEditModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    copyShop() {

    },

    deleteShop(shop) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: this.$t('alert.title'),
        text: this.$t('alert.message'),
        icon: "warning",
        confirmButtonText: this.$t('alert.yes'),
        cancelButtonText: this.$t('alert.no'),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          axios.delete(`/shop/by-admin/${shop.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: {}
          }).then(() => {
            swalWithBootstrapButtons.fire(
                this.$t('message.deleted'),
                this.$t('user-shops.delete.success'),
                "success"
            );

            this.shops = this.shops.filter(element => element.id !== shop.id);
            this.$refs.table.refresh()
          }).catch((error) => {
            errorCatcher.catchErrors(error)
          });
        }
      });
    },

    categoriesShop(shop) {
      routerHelper.push('/dashboard/shop/categories', {"shopId": shop.id})
    },

    shopProducts(shop) {
      routerHelper.push('/dashboard/user/shop/products', {"shopId": shop.id})
    },

    openAllegro(shop) {
      routerHelper.push('/dashboard/user/allegro', {"shopId": shop.id})
    },

    editShop(shop) {
      this.shop = Object.assign({}, shop);
      this.createOrEditShowModal = true
    },

    getUserEmail(userId) {
      if (!this.users) {
        return {}
      }

      let user = this.users.get(userId);
      if (!user) {
        return {}
      }

      return user.email;
    },

    getEngineName(engineId) {
      if (!this.engines) {
        return {}
      }

      let engine = this.engines.get(engineId);
      return engine ? engine.i18nTranslation : "";
    },

    /**
     * hide modal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideCreateOrEditModal(e) {
      this.submitted = false;
      this.createOrEditShowModal = false;
      this.shop = {};
    },

    // eslint-disable-next-line no-unused-vars
    hideCopyShopModal(e) {
      this.submitted = false;
      this.copyShopModal = false;
      this.shop = {};
    },

    getFields() {
      return [
        {key: "name", label: this.$t('table.shop-name')},
        {key: "domain", label: this.$t('table.domain')},
        {key: "status", label: 'Status'},
        {key: "emailAddress", label: this.$t('message.shop-email')},
        {key: "engine", label: this.$t('table.engine')},
        {key: "user", label: this.$t('table.user')},
        {key: "wordpressPlugin", label: 'Wtyczka WordPress' },
        {key: "productLimit", label: this.$t('message.product-limit')},
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('shops.title'),
          active: true
        }
      ]
    },

    async loadShops() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const { data } = await axios.get(`/shop/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            languageId: this.$store.getters["translation/getCurrentLocale"]
          }
        });

        this.shops = data
        await this.loadShopsCount()
        await this.loadUsers()
        await this.loadWordPressPlugins()
        await this.loadProductLimits()

        if (this.mounted) {
          this.$refs.table.refresh()
        }

      } catch (error) {
        this.shops = []
      }

      return this.shops;
    },

    async loadShopsCount() {
      try {
        const result = await axios.get(`/shop/count`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            filter: this.table.filter,
            languageId: this.$store.getters["translation/getCurrentLocale"]
          }
        });

        this.table.totalRows = result.data;
        this.table.rows = result.data;
      } catch (error) {
        this.table.totalRows = 0
        this.table.rows = 0
      }
    },

    async loadMarkets() {
      const result = await axios.get(`/market`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.markets = new Map(result.data.map((obj) => [obj.id, {
        "id:": obj.id,
        "i18nTranslation": obj.i18nTranslation
      }]));
    },

    async loadWordPressPlugins() {
      if (this.shops.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (let shop of this.shops) {
        if (shop.wordpressPluginMarketplaceOrderId) {
          marketplaceOrderIds.push(shop.wordpressPluginMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.wordPressPlugins = new Map(data.map((obj) => [obj.shopId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductLimits() {
      if (this.shops.length === 0) {
        return;
      }

      const marketplaceOrderIds = [];
      for (let shop of this.shops) {
        if (shop.productLimitMarketplaceOrderId) {
          marketplaceOrderIds.push(shop.productLimitMarketplaceOrderId);
        }
      }

      if (marketplaceOrderIds.length === 0) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            marketplaceOrderIds: marketplaceOrderIds.join(",")
          }
        });

        this.productLimits = new Map(data.map((obj) => [obj.shopId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEngines() {
      const result = await axios.get(`/engine`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.engines = new Map(result.data.map((obj) => [obj.id, {
        "id:": obj.id,
        "i18nTranslation": obj.i18nTranslation
      }]));

    },

    async loadUsers() {
      if (this.shops.length === 0) {
        return;
      }

      const ids = [];
      for (let shop of this.shops) {
        ids.push(shop.userId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    async loadChangeShopUsers(email) {
      this.changeShopUser.loading = true

      if (!email) {
        const result = await axios.get(`/user/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": 0,
            "size": 10
          }
        });

        const usersIds = []
        for (const user of result.data) {
          usersIds.push(user.id)
        }

        this.changeShopUser.users = new Map(result.data.map((obj) => [obj.id, obj]));
        this.changeShopUser.usersIds = usersIds

        if (this.changeShopUser.selected.id) {
          this.changeShopUser.usersIds.push(this.changeShopUser.selected.id)
          this.changeShopUser.users.set(this.changeShopUser.selected.id, this.changeShopUser.selected.user)
        }

        this.changeShopUser.loading = false
        return
      }

      const result = await axios.get(`/user/by-email`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "email": email
        }
      });

      const usersIds = []
      for (const user of result.data) {
        usersIds.push(user.id)
      }

      this.changeShopUser.users = new Map(result.data.map((obj) => [obj.id, obj]));
      this.changeShopUser.usersIds = usersIds

      if (this.changeShopUser.selected.id) {
        this.changeShopUser.usersIds.push(this.changeShopUser.selected.id)
        this.changeShopUser.users.set(this.changeShopUser.selected.id, this.changeShopUser.selected.user)
      }

      this.changeShopUser.loading = false
    },

    hideChangeShopUserModal() {
      this.changeShopUser.visibleModal = false
    },

    changeShopUserMethod() {
      this.submitted = true;

      this.$v.changeShopUser.$touch();
      if (this.$v.changeShopUser.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "shopId": this.changeShopUser.shopId,
        "userId": this.changeShopUser.selected.id,
      });

      axios.post(`/shop/change-user`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.$bvToast.toast('Pomyślnie zmieniono użytkownika sklepu!', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.submitted = false;
        setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async openModalChangeShopUser(item) {
      await this.loadChangeShopUsers()
      this.changeShopUser.shopId = item.id
      this.changeShopUser.visibleModal = true
    },

    getChangeShopUser(id) {
      if (!this.changeShopUser.users) {
        return {}
      }

      const user = this.changeShopUser.users.get(id)
      if (!user) {
        return {}
      }

      return user
    },

    selectShopUser(id) {
      this.changeShopUser.selected.id = id
      this.changeShopUser.selected.user = this.getChangeShopUser(id)
    },

    openCreateOrderModal(shop, type) {
      this.$refs.createOrderModal.openModal(() => this.$refs.table.refresh(), type, shop.userId, shop.id)
    },

  },

  async created() {
    try {
      await this.loadMarkets()
      await this.loadEngines()
    } catch (error) {
      console.log(error)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('shops.title')" :items="getItems()"/>
    <create-order-modal ref="createOrderModal"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="clickable-element btn btn-success" @click="createOrEditShowModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('user-shops.create-shop.title') }}
                </a>
              </div>
            </div>

              <div class="py-2">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="table.perPage" :options="table.pageOptions" size="sm"></b-form-select>&nbsp;{{
                          $t('table.entries')
                        }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                      @keyup.enter="setToFirstPageAndRefresh"
                                      @input="setToFirstPageAndRefresh"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-right">
                      <label class="d-inline-flex align-items-center mx-2">
                        <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2"/>
                      </label>
                      <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                        strony
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="row py-3">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                      :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table
                      ref="table"
                      :current-page="table.currentPage"
                      :fields="getFields()"
                      :filter-included-fields="table.filterOn"
                      :items="loadShops"
                      :per-page="table.perPage"
                      :sort-by.sync="table.sortBy"
                      :sort-desc.sync="table.sortDesc"
                      responsive="sm"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                      @filtered="elements => paginationHelper.onFiltered(table, elements)">
                    <template v-slot:cell(status)>
                      <span class="badge badge-soft-success font-size-11">{{ $t('table.active') }}</span>
                    </template>

                    <template v-slot:cell(engine)="{ item }">
                      <span>{{ $t(getEngineName(item.engineId)) }}</span>
                    </template>

                    <template v-slot:cell(user)="{ item }">
                      <span>{{ getUserEmail(item.userId) }}</span>
                    </template>

                    <template v-slot:cell(productLimit)="{ item }">
                      <span>Limit produktów: <b>{{ item.productLimit }}</b></span>
                      <p v-if="getProductLimit(item)">Data ważności limitu produktów: <b>{{ marketplaceOrderHelper.getExpireTime(getProductLimit(item)) }}</b></p>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <div class="button-items mx-2">
                        <a id="edit-shop-info"
                           @click="editShop(item)"
                           class="clickable-element mr-2 text-primary">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <b-tooltip target="edit-shop-info" placement="left">{{ $t('user-shops.buttons.edit') }}
                        </b-tooltip>

                        <a id="change-shop-user-info" @click="openModalChangeShopUser(item)"
                           class="clickable-element mr-2 text-primary"> <i class="mdi mdi-account font-size-18"></i></a>
                        <b-tooltip target="change-shop-user-info" placement="left">Zmień użytkownika sklepu</b-tooltip>

                        <a id="delete-shop"
                           @click="deleteShop(item)"
                           class="clickable-element mr-2 text-danger">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>

                        <b-tooltip target="delete-shop" placement="left">Usuń sklep</b-tooltip>

                        <a :href="`/dashboard/core/shop/show/${item.id}`" class="clickable-element mr-2 text-primary"><i
                            class="mdi mdi-information font-size-18"></i></a>

                        <template v-if="$store.getters['engine/getEngineName'](item.engineId) === 'engine.wordpress'">
                          <a @click="openCreateOrderModal(item, 'WORDPRESS_PLUGIN')"
                             :id="`open-create-order-${item.id}`" class="clickable-element mr-2 text-info"><i
                              class="mdi mdi-shopping font-size-18"></i></a>
                          <b-tooltip :target="`open-create-order-${item.id}`" placement="left">Wtyczka WordPress
                          </b-tooltip>
                        </template>

                        <a @click="openCreateOrderModal(item, 'SHOP_PRODUCT_LIMIT_INCREASE')"
                           :id="`open-create-order-product-limit-${item.id}`"
                           class="clickable-element mr-2 text-success"><i class="mdi mdi-shopping font-size-18"></i></a>
                        <b-tooltip :target="`open-create-order-product-limit-${item.id}`" placement="left">Limit
                          produktów
                        </b-tooltip>
                      </div>

                      <!--                      <a id="delete-shop-info"-->
                      <!--                         @click="deleteShop(item)"-->
                      <!--                         class="clickable-element mr-2 text-danger">-->
                      <!--                        <i class="mdi mdi-trash-can font-size-18"></i>-->
                      <!--                      </a>-->

                      <!--                      <b-tooltip target="delete-shop-info" placement="left">{{ $t('user-shops.buttons.delete')}}</b-tooltip>-->

                      <!--                      <a id="shop-categories-info" @click="categoriesShop(item)"-->
                      <!--                         class="clickable-element mr-2 text-primary">-->
                      <!--                        <i class="mdi mdi-view-dashboard font-size-18"></i>-->
                      <!--                      </a>-->

                      <!--                      <b-tooltip target="shop-categories-info" placement="left">{{ $t('user-shops.buttons.categories')}}</b-tooltip>-->

                      <!--                      <a id="shop-products-info" @click="shopProducts(item)"-->
                      <!--                         class="clickable-element mr-2 text-primary">-->
                      <!--                        <i class="mdi mdi-book-open-variant font-size-18"></i>-->
                      <!--                      </a>-->

                      <!--                      <b-tooltip target="shop-products-info" placement="left">{{ $t('user-shops.buttons.products')}}</b-tooltip>-->

                      <!--                      <a id="allegro" @click="openAllegro(item)" class="clickable-element">-->
                      <!--                        <i class="ri-shopping-bag-fill align-middle mr-1"></i>-->
                      <!--                      </a>-->

                      <!--                      <b-tooltip target="allegro" placement="left">Allegro</b-tooltip>-->
                    </template>

                    <template v-slot:cell(wordpressPlugin)="{ item }">
                      <span v-if="isWordPress(item)">
                        Wersja wtyczki: <b>{{ isWordPressPluginActive(item) ? 'PRO' : 'FREE' }}</b>
                        <p v-if="isWordPressPluginActive(item)">Data ważności wtyczki PRO: <b>{{ marketplaceOrderHelper.getExpireTime(getWordPressPlugin(item)) }}</b></p>
                      </span>
                      <span v-else>-</span>
                    </template>
                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{
                          $t('table.entries-footer', {
                            'amount': paginationHelper.getElementsAmount(table),
                            'elements': paginationHelper.getElementsCount(table),
                            'all': table.totalRows
                          })
                        }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="table.currentPage" :per-page="table.perPage"
                                      :total-rows="table.rows"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="createOrEditShowModal"
        :title="$t(shop.id ? 'user-shops.edit-shop.editing' : 'user-shops.create-shop.creating')"
        title-class="font-18"
        hide-footer
        @hide="hideCreateOrEditModal"
        @esc="hideCreateOrEditModal">
      <form>
        <div class="form-group">
          <label>{{ $t('message.engine') }}</label>
          <select v-model="shop.engineId" class="custom-select"
                  :class="{ 'is-invalid': submitted && $v.shop.engineId.$error }">
            <option v-for="[key, value] in engines" :key="key" :value="key">{{ $t(value.i18nTranslation) }}</option>
          </select>

          <div v-if="submitted && $v.shop.engineId.$error" class="invalid-feedback">
            <span v-if="!$v.shop.engineId.required">{{ $t('message.required') }}</span>
          </div>
        </div>

        <template v-if="markets">
          <div class="form-group">
            <label>{{ $t('message.market') }}</label>
            <select v-model="shop.marketId" class="custom-select"
                    :class="{ 'is-invalid': submitted && $v.shop.marketId.$error }">
              <option v-for="[key, value] in markets" :key="key" :value="key">{{ $t(value.i18nTranslation) }}</option>
            </select>

            <div v-if="submitted && $v.shop.marketId.$error" class="invalid-feedback">
              <span v-if="!$v.shop.marketId.required">{{ $t('message.required') }}</span>
            </div>
          </div>
        </template>

        <div class="form-group">
          <label for="name">{{ $t('message.name') }}</label>
          <input
              id="name"
              v-model="shop.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.shop.name.$error }"/>
          <div
              v-if="submitted && !$v.shop.name.required"
              class="invalid-feedback"
          >{{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group" v-if="getEngineName(shop.engineId) !== 'engine.virtual'">
          <ecat-multiselect @change="value => shop.industryId = value"
                            save-id="id"
                            view-id="name"
                            :label="$t('message.industry')"
                            placeholder="Wybierz branże"
                            load-url="/industry/list/pagination"
                            query-url="/industry/by-name"
                            list-name="industries"
                            :set-value="shop.industryId"
                            param="name"
                            :class="{ 'is-invalid': submitted && $v.shop.industryId.$error }">
          </ecat-multiselect>

          <div v-if="submitted && $v.shop.industryId.$error" class="invalid-feedback">
            <span v-if="!$v.shop.industryId.required">{{ $t('message.required') }}</span>
          </div>
        </div>

        <template v-if="getEngineName(shop.engineId) !== 'engine.virtual'">
          <div class="form-group">
            <label for="name">{{ $t('message.domain') }}</label>
            <input
                id="name"
                v-model="shop.domain"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.shop.domain.$error }"/>
            <div v-if="submitted && !$v.shop.domain.required" class="invalid-feedback">{{ $t('message.required') }}
            </div>
            <div v-if="submitted && !$v.shop.domain.domain" class="invalid-feedback">
              {{ $t('user-shops.create-shop.is-domain') }}
            </div>
          </div>

          <div class="form-group">
            <label for="email">{{ $t('message.shop-email') }}</label>
            <input
                id="email"
                v-model="shop.emailAddress"
                type="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.shop.emailAddress.$error }"/>
            <div v-if="submitted && $v.shop.emailAddress.$error" class="invalid-feedback">
              <span v-if="!$v.shop.emailAddress.required">{{ $t('message.required') }}</span>
              <span v-if="!$v.shop.emailAddress.email">{{ $t('message.required') }}</span>
            </div>
          </div>
        </template>

        <template v-if="getEngineName(shop.engineId) !== 'engine.virtual'">
          <h4 class="text-center">{{ $t('user-shops.integration') }}</h4>

          <div class="form-group">
            <label for="google_analytics">Google Analytics</label>
            <input
                id="google_analytics"
                v-model="shop.googleAnalytics"
                type="text"
                name="google_analytics"
                class="form-control"/>
          </div>

          <div class="form-group">
            <label for="google_tag_manager">Google Tag Manager</label>
            <input
                id="google_tag_manager"
                v-model="shop.googleTagManager"
                type="text"
                name="google_tag_manager"
                class="form-control"/>
          </div>

          <div class="form-group">
            <label for="facebook_pixel">{{ $t('user-shops.facebook-pixel') }}</label>
            <input
                id="facebook_pixel"
                v-model="shop.facebookPixel"
                type="text"
                name="facebook_pixel"
                class="form-control"/>
          </div>
        </template>

        <div class="text-right">
          <b-button @click="createOrEditShop" variant="success">
            {{ $t(shop.id ? 'user-shops.edit-shop.title' : 'user-shops.create-shop.title') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideCreateOrEditModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-2"
        :scrollable="true"
        v-model="copyShopModal"
        :title="$t('user-shops.copy-shop.title')"
        title-class="font-18"
        hide-footer>
      <form>

        <div class="form-group">
          <!--          <multiselect-->
          <!--              v-model="this.shop"-->
          <!--              label="name" track-by="name"-->
          <!--              :options="this.shops"-->
          <!--              placeholder="Wybierz sklep źródłowy"-->
          <!--              :searchable="true"></multiselect>-->
        </div>

        <div class="text-right">
          <b-button @click="copyShop" variant="success">{{ $t('user-shops.copy-shop.title') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideCopyShopModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="changeShopUser.visibleModal"
        title="Zmiana użytkownika sklepu"
        title-class="font-18"
        hide-footer
        @hide="hideChangeShopUserModal"
        @esc="hideChangeShopUserModal">
      <form>
        <div class="form-group">
          <label for="user">Użytkownik</label>
          <multiselect
              :value="changeShopUser.selected.id"
              @select="value => selectShopUser(value)"
              :options="changeShopUser.usersIds"
              :loading="changeShopUser.loading"
              :custom-label="value => getChangeShopUser(value).email"
              @search-change="value => loadChangeShopUsers(value)"
              placeholder="Wybierz nowego użytkownika"
              :class="{ 'is-invalid': submitted && $v.changeShopUser.selected.id.$error }"
              :show-labels="false"
          >
            <span slot="noOptions">{{ $t('message.list-is-empty') }}</span>
          </multiselect>

          <div v-if="submitted && $v.changeShopUser.selected.id.$error" class="invalid-feedback">
            <span v-if="!$v.changeShopUser.selected.id.required">{{ $t('message.required') }}</span>
          </div>
        </div>

        <div class="text-right">
          <b-button @click="changeShopUserMethod" variant="success">Zmień użytkownika</b-button>
          <b-button class="ml-1" variant="danger" @click="hideChangeShopUserModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>